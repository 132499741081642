import ky, { HTTPError, NormalizedOptions } from "ky";
import { apiUrl } from "./constants";
import { getPersistedValue, logout } from "./utils";
import { tolgee } from "./tolgee";

type ErrorResponse = {
  errors?: Record<string, string[]>;
};

export const request = ky.create({
  prefixUrl: apiUrl,
  headers: {
    Accept: "application/json",
  },

  hooks: {
    beforeRequest: [
      async (options) => {
        const token = await getPersistedValue("token");
        if (token) {
          options.headers.set("Authorization", `Bearer ${token}`);
        }

        const locale = tolgee.getLanguage();
        if (locale) {
          options.headers.set("Accept-Language", locale);
        }

        return options;
      },
    ],
    beforeError: [
      async (error) => {
        if (error.response?.status === 401) {
          await logout();
        }

        if (error.response) {
          const data = (await error.response.json()) as ErrorResponse;
          if (data.errors) {
            const fields: Record<string, string> = Object.entries(
              data.errors,
            ).reduce((acc: Record<string, string>, [field, errors]) => {
              acc[field] = (errors as string[])[0];
              return acc;
            }, {});

            return new ValidationError(
              error.response,
              error.request,
              error.options,
              fields,
            );
          }
        }

        return error;
      },
    ],
  },
});

export class ValidationError extends HTTPError {
  constructor(
    response: Response,
    request: Request,
    options: NormalizedOptions,
    public fields: Record<string, string>,
  ) {
    super(response, request, options);
    this.name = "ValidationError";
  }
}

export function getValidationError(
  error: Error | null,
  field: string,
): string | null {
  if (!error == null) {
    return null;
  }

  if (!(error instanceof ValidationError)) {
    return null;
  }

  if (error.fields[field]) {
    return error.fields[field];
  }

  return null;
}

export async function hasToken() {
  return (await getPersistedValue("token")) !== null;
}
